import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

function Loading() {
    return (
        <div className="d-flex justify-content-center mt-4 pt-5">
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner> 
        </div>
    )
}

export default Loading

const filterData = (data, filters) => {
    let temp = [...data];

    Object.entries(filters).forEach(([k, v]) => {
        temp = temp.filter(item => item[k] && item[k].toLowerCase() === v.toLowerCase())
    })

    return temp
}

export default filterData
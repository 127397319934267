import { DropDown, Radios } from 'components'
import React, { useEffect, useState } from 'react'


function DV360Targets(props) {
    const { existingDV360s, targets, setTargets, setAddTarget, setEditTargetKey, editTargetKey, published } = props
    const [ accountId, setAccountId ] = useState(null)
    const [ userType, setUserType ] = useState("Partner")
    const [ userId, setUserID ] = useState(null)
    const [ channelType, setChannelType ] = useState("New channel")
    const [ channelId, setChannelId ] = useState(null)
    const [ accountIdError, setAccountIdError ] = useState(null)
    const [ userIdError, setUserIdError ] = useState(null)
    const [ channelIdError, setChannelIdError ] = useState(null)

    useEffect(() => {
        if (editTargetKey) {
            const tempTarget = targets[parseInt(editTargetKey)]
            setAccountId(tempTarget.accountId)
            setUserType(tempTarget.userType)
            setUserID(tempTarget.userId)
            setChannelType(tempTarget.channelType)
            setChannelId(tempTarget.channelId || tempTarget.channelName)
        }
    }, [editTargetKey])


    const updateTargets = (target) => {
        const tempTargets = [ ...targets ]
        editTargetKey ? tempTargets[parseInt(editTargetKey)] = target : tempTargets.push(target)
        setTargets(tempTargets)
    }

    const onClick = () => {
        if (!accountId) {
            setAccountIdError("No DV360 link selected.")
            return
        }

        if (!userId) {
            setUserIdError("No partner/advertiser provided.")
            return
        }

        if (!channelId) {
            setChannelIdError("No channel provided.")
            return
        }

        const target = { accountId, userType, userId, channelType }
        const channelKey = channelType === "Existing channel" ? "channelId" : "channelName"
        target[channelKey] = channelId
        updateTargets(target)
        setAddTarget(null)
        setEditTargetKey(null)
    }

    useEffect(() => {
        setAccountIdError(null)
        setUserIdError(null)
        setChannelIdError(null)
    }, [accountId, userType, userId, channelId, channelType])


    const AccountIDComponent = () => {
        if (existingDV360s.length === 1) {
            const value = existingDV360s[0]
            setAccountId(value)
            return (
                <input className='mt-1 py-2 px-3 border rounded d-block w-100 bg--secondary' defaultValue={ value } readOnly />
            )
        }
        return (
            <DropDown options={ existingDV360s } className="w-100 mt-1 position-relative" childClassName="w-100" setSelected={ setAccountId } defaultValue={ accountId || "Select DV360 account" }/>
        )
    }

    return (
        <div className='w-100 p-4 border rounded mb-3'>
            <span className='label-text'>DV360 Account</span>
            <AccountIDComponent />
            {
                accountIdError && (
                    <span className='text-danger position-absolute ml-2' data-testid="account-error">
                        { accountIdError }
                    </span>
                )
            }
            <Radios items={ ["Partner", "Advertiser"] } selected={ userType } setSelected={ setUserType } childClassName="col-5 mt-4 ml-2 mb-3" />
            <div className='row align-items-center mr-1'>
                <span className="label-text ml-1 col-1">ID</span>
                <input className='border rounded px-3 py-2 ml-3 flex-grow-1' data-testid="textbox-user" onChange={e => setUserID(e.target.value)} value={ userId }/>
                {
                    userIdError && (
                        <span className='text-danger position-absolute ml-5 mt-5 pt-1 pl-4' data-testid="user-error">
                            { userIdError }
                        </span>
                    )
                }
            </div>
            <Radios items={ ["New channel"] } selected={ channelType } setSelected={ setChannelType } childClassName="col-5 mt-4 ml-2 mb-3" />
            <div className='row align-items-center mr-1'>
                <span className="label-text ml-1 col-1">{ channelType === "Existing channel" ? "ID" : "Name" }</span>
                <input className='border rounded px-3 py-2 ml-3 flex-grow-1' data-testid="textbox-channel"  onChange={e => setChannelId(e.target.value)} value={ channelId } />
                {
                    channelIdError && (
                        <span className='text-danger position-absolute ml-5 mt-5 pt-1 pl-4' data-testid="channel-error">
                            { channelIdError }
                        </span>
                    )
                }
            </div>
            <div className='text-right mt-4'>
                <button className='btn btn-primary px-3 py-2' onClick={ onClick }>Save</button>
            </div>
        </div>
    )
}

export default DV360Targets
import React, { useEffect, useState } from 'react'
import { webTabs } from 'constants/tabConstants'
import TargetingSection from './TargetingSection'
import { analyze } from 'services/Analyze'
import { validateInput } from 'utils/validations'
import QueryResults from './QueryResults'


function QueryPages(props) {
    const { pageStates, setMessageQueue, setNinaLoader, setPageStates } = props
    const [ activeTab, setActiveTab ] = useState(pageStates.activeTab || Object.keys(webTabs)[0])
    const Component = webTabs[activeTab] || Object.values(webTabs)[0]
    const pageState = pageStates[activeTab]
    const [ inputValue, setInputValue ] = useState("")
    const [ queryResults, setQueryResults ] = useState(null)
    const [ errorMessage, setErrorMessage ] = useState(null)
    const componentProps = {
        pageKey: activeTab,
        className: "form-group p-0 mt-4 mb-0",
        setInputValue,
        inputValue,
    }

    const handleClick = async (event) => {
        event.preventDefault()
        setMessageQueue([])
        setQueryResults(null)
        
        const error = validateInput(inputValue, activeTab)
        if (error) setErrorMessage(error)
        else {
            
            setNinaLoader(true)
            const response = await analyze(activeTab, inputValue)
            const responseState = {}

            if ("error" in response) setMessageQueue([response])
            else {
                setQueryResults(response)
                responseState["queryResults"] = response
            }

            const tempState = { ...pageStates }
            tempState.activeTab = activeTab
            tempState[activeTab] = { ...pageState, inputValue, ...responseState }
            setPageStates(tempState)

        }

        setTimeout(() => setNinaLoader(false), 300)
    }
    
    useEffect(() => {
        setMessageQueue([])
        setErrorMessage("")
    }, [inputValue])

    useEffect(() => {
        setInputValue(pageState ? pageState.inputValue || "" : "")
        setQueryResults(pageState ? pageState.queryResults : null)
    }, [activeTab])


    return (
        <div>
            <div className="d-flex justify-content-start tab-section-border mt-5">
            {
                Object.keys(webTabs).map(key => (
                    <div className="d-flex flex-column justify-content-start tabs" key={ key }>
                        <button
                            type="button"
                            className={ `bg-white pb-3 px-4 border-0 tab-${ key === activeTab && "active"}` }
                            onClick={() => setActiveTab(key)}
                        >
                            <span className="px-1">{ key }</span>
                        </button>
                        {
                            key === activeTab && ( 
                                <div className="bg-white p-0 outline-active"></div>
                            )
                        }
                    </div>
                ))
            }
            </div>
            <div className={ `d-flex ${ activeTab === "URL" ? "align-items-end" : "flex-column" }` }>
                <Component { ...componentProps } >
                {
                    errorMessage && (
                        <span className="text-danger error-text" data-testid={ `error-${activeTab}` }>{ errorMessage }</span>
                    )
                }
                </Component>
                <div className={ "text-right mt-2" }>
                    <button type="button" id="taxonomy-button" className="btn btn-primary px-4 ml-3"
                        disabled={ typeof inputValue === "string" && !inputValue.trim() }
                        onClick={ handleClick }
                    >Analyze { activeTab }</button>
                </div>
            </div>
            {
                queryResults ? (
                    <>
                        <QueryResults { ...queryResults } activeTab={ activeTab } />
                        <TargetingSection taxonomies={ queryResults.taxonomies } { ...props } activeTab={ activeTab } />
                    </>
                ) : (
                    <></>
                )
            }
        </div>
    )
}

export default QueryPages

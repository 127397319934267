import React, { useEffect, useState } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

function SidebarItem(props) {
    const { item, collapse } = props
    const [ displayProp, setDisplayProp ] = useState("d-inline")
    const match = useRouteMatch()
    const [ isSelected, setIsSelected ] = useState(false)

    useEffect(() => {
        if (collapse) {
            setDisplayProp("d-none")
        } else {
            setTimeout(() => setDisplayProp("d-inline"), 100)
        }
    }, [collapse])


    return (
        <>
            <NavLink 
                to={ `${match.url}${item.path}` } 
                key={ item.key } 
                className={ `sidebar-menu-item ${ isSelected ? `text-selected ${(!!item.subMenu && !collapse) || "bg--secondary rounded"}` : "none" }` } 
                isActive={match => setIsSelected(!!match)}
            >
                <img src={ isSelected ? item.selected : item.icon } alt="menu icon" height="24" width="22"/>
                <span className={ `ml-2 ${ displayProp }` }>{ item.text }</span>
            </NavLink>
            <div className={ `${!collapse || "d-none" }` }>
                {
                    item.subMenu && item.subMenu.map(sub => (
                        <NavLink 
                            to={ `${match.url}${item.path + sub.path}` } 
                            key={ sub.key } 
                            className="sidebar-menu-item ml-5" 
                            activeClassName="text-selected bg--secondary rounded"
                        >
                            <span className={ `ml-2 ${ displayProp }` }>{ sub.text }</span>
                        </NavLink>
                    ))
                }
            </div>    
        </>
    )
}

export default SidebarItem

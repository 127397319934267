import React from 'react'


function InputBox(props) {
    const { pageKey, inputValue, setInputValue, className } = props

    return (
        <div className={ `${className} col-7` }>
            <label htmlFor={ `${pageKey}-query` } className="label-text d-flex justify-content-between">
                { pageKey }
            </label>
            <div className="row px-3">
                <input 
                    type="text" 
                    className="col form-control" 
                    id={ `${pageKey}-query` }
                    placeholder="https://www.google.com/"
                    onChange={e => setInputValue(e.target.value)}
                    value={ inputValue }    
                />
            </div>
            { props.children }
        </div>
    )
}

export default InputBox
import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import dashboardPages from 'constants/dashboardPages'
import HomePage from './HomePage'
import PageLayout from './PageLayout';

function Routes() {
    const match = useRouteMatch()

    return (
        <Switch>
            <Route exact path={ `${match.path}/`}>
                <HomePage />
            </Route>
            {
                dashboardPages.map(item => (
                    <Route path={ `${match.path}${item.path}` }>
                        <PageLayout item={ item } /> 
                    </Route>
                ))
            }
            <Redirect to="/" />
        </Switch>
    )

}

export default Routes

import React from 'react'
import { ReactComponent as CollapseIcon } from './assets/CollapseIcon.svg';

function CollapseButton(props) {
    const { collapseButtonVisibility, collapse, setCollapse } = props
    
    return (
        <>
            {
                collapseButtonVisibility && (
                    <CollapseIcon 
                        className={ `collapse-button ${ collapse && "rotate-button" }`} 
                        onClick={() => collapse ? setCollapse(false) : setCollapse(true) }  
                    />
                )
            }
        </>
    )
}

export default CollapseButton

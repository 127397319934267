import React from 'react'
import Request from 'services/Request'
import { pathFor } from 'constants/apiPaths'

function DV360Accounts(props) {
    const { existingDV360s, fetchDV360s, setMessageQueue } = props

    const handleDelink = async (label) => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.DeLinkDV360, { label })
            if (statusCode === 200) {
                setMessageQueue([jsonResponse])
                fetchDV360s()
            }
        } catch (err) {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }

    return (
        <>
        {
            existingDV360s && existingDV360s.map(item => (
                <div className='w-100 px-4 py-3 border rounded mb-3 d-flex align-items-center'>
                    <span className='label-text'>Name</span>
                    <input className='border--light rounded px-3 py-2 ml-4 bg--secondary flex-grow-1 my-1' value={ item } readOnly={ true } />
                    <button className='btn btn-primary px-3 ml-3 py-2' onClick={() => handleDelink(item)}>Delink</button>
                </div>
            ))
        }
        </>
    )
}

export default DV360Accounts
import React, { useEffect, useState } from 'react'
import MessageQueue from 'components/MessageQueue';

function PageLayout(props) {
    const { item } = props
    const { text, component: Component } = item
    const [ messageQueue, setMessageQueue ] = useState([])
    const [ title, setTitle ] = useState(text)

    useEffect(() => setMessageQueue([]), [text])

    return (
        <div>
            <h2>{ title }</h2>
            <Component setMessageQueue={ setMessageQueue } setTitle={ setTitle } />
            <MessageQueue messageQueue={ messageQueue } />
        </div>
    )
}

export default PageLayout
import React from 'react'
import Welcome from 'assets/images/Welcome.svg'


function HomePage() {

    return (
        <>
            <h2>Welcome to NinaData</h2>
            <div className="d-flex justify-content-center align-items-center">
                <img src={ Welcome } alt="welcome" className="welcome" />
            </div>
        </>
    )
}

export default HomePage

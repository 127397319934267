import React from 'react'
import { Helper, Table } from 'components'
import { taxonomiesTable } from 'constants/tableConstants'


function QueryResults({keywords, taxonomies, avg_score, activeTab}) {

    return (
        <div className="mt-4">
            {
                keywords && (
                    <>
                        <div className="label-text d-flex justify-content-between">
                            Keywords
                            {
                                avg_score && avg_score >= 60 && (
                                    <span className='d-flex align-items-center'>
                                        <Helper itemKey="avgScore" variant="gray" arg={ activeTab } />
                                        Average keywords' score:&nbsp; 
                                        <span className="text-primary">
                                            { avg_score }%
                                        </span>
                                    </span>
                                )
                            }
                        </div>
                        <div className="p-3">
                            { keywords.join(", ") }
                        </div>
                    </>
                )
            }
            {
                taxonomies && (
                    <div className="card w-100 mt-4">
                        <Table tableData={{ 
                                tableHead: taxonomiesTable, 
                                tableBody: taxonomies.map((item, idx) => ({
                                    taxonomy: item, rank: `${idx + 1}`
                                })) 
                            }} 
                            className="table table-borderless table-striped" 
                        />
                    </div>
                )
            }
        </div>
        )
}

export default QueryResults
import React, { useEffect, useState } from 'react'
import { CheckBoxes } from 'components';
import { pathFor } from 'constants/apiPaths';
import Request from 'services/Request';
import { previewWebDataset } from 'constants/tableConstants';


function TargetingSection(props) {
    const { taxonomies, pageStates, activeTab, setMessageQueue, setResults, setPageStates, setNinaLoader, setMilestone } = props
    const [ intentCheckboxes, setIntentCheckboxes ] = useState(["Informational"])
    const [ emotionCheckboxes, setEmotionCheckboxes ] = useState(["Positive"])
    const [ languageCheckboxes, setLanguageCheckboxes ] = useState(["English"])
    const disabled =  !taxonomies || !intentCheckboxes.length || !emotionCheckboxes.length || !languageCheckboxes.length
    const intents = intentCheckboxes.map(val => val.toLowerCase().replace(" ", "_"))
    
    const handleClick = async (event) => {
        event.preventDefault()
        setMessageQueue([])
        setResults(null)
        setNinaLoader(true)
        
        const tempState = { ...pageStates }
        tempState.activeTab = activeTab
        tempState[activeTab] = { ...tempState[activeTab], intentCheckboxes, emotionCheckboxes, languageCheckboxes }
        setPageStates(tempState)
        
        const payload = {
            taxonomies,
            intents,
            emotions: emotionCheckboxes,
            languages: languageCheckboxes
        }

        try {
            const { statusCode, jsonResponse } = await Request(pathFor.RecommendationEngine, payload)
            
            if (statusCode === 200) {
                if ("urls" in jsonResponse) {
                    setResults({ 
                        tableData: { 
                            tableHead: previewWebDataset, 
                            tableBody: jsonResponse.urls.map(item => {
                                let data = { ...item }
                                const taxonomy = data.taxonomy
                                const intent = intents.filter(i => data.intent.includes(i))[0] 
                                data.is_positive_emotion = data.is_positive_emotion ? "Positive" : "Negative"
                                data.intent = intent.charAt(0).toUpperCase() + intent.slice(1).replace("_", " ")
                                data.taxonomy = taxonomy.label
                                data.score = `${(taxonomy.score * 100).toFixed(2)}%`
                                return data
                            }) 
                        } 
                    })
                    setMilestone(2)
                } 
            } else if (statusCode === 400) {
                setMessageQueue([{error: "Failed to suggest. Please try again."}])
            } else {
                setMessageQueue([jsonResponse])
            }
        } catch (error) {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    
        setTimeout(() => setNinaLoader(false), 300)
    }

    useEffect(() => {
        const pageState = pageStates[activeTab]
        if (pageState) {
            setIntentCheckboxes(pageState.intentCheckboxes || [])
            setEmotionCheckboxes(pageState.emotionCheckboxes || [])
            setLanguageCheckboxes(pageState.languageCheckboxes || [])
        }
    }, [activeTab])


    return (
        <>
            <div  className="mt-4">
                <label className="label-text">Intent</label>
                <div className="ml-2">
                    <CheckBoxes 
                        items={ ["Transactional", "Navigational", "Informational", "Navigational informational", "Navigational transactional", "Transactional download"] } 
                        selected={ intentCheckboxes } setSelected={ setIntentCheckboxes } 
                        childClassName="col-4 my-2"
                    />
                </div>
            </div>
            <div className="mt-4">
                <label className="label-text">Emotion</label>
                <div className="ml-2">
                    <CheckBoxes items={ ["Positive", "Negative"] } selected={ emotionCheckboxes } setSelected={ setEmotionCheckboxes } childClassName="col-4 my-2" />
                </div>
            </div>
            <div className="mt-4">
                <label className="label-text">Language</label>
                <div className="ml-2">
                    <CheckBoxes items={ ["English", "Spanish", "Finnish", "Danish", "Norwegian", "Swedish"] } selected={ languageCheckboxes } setSelected={ setLanguageCheckboxes } childClassName="col-4 my-2" />
                </div>
            </div>
            <div className="text-right">
                <button type="submit" id="urls-button" className="btn btn-primary ml-3 mb-4 px-4" onClick={ handleClick } disabled={ disabled }>Suggest URLs</button>
            </div>
        </>
    )
}

export default TargetingSection
import React, { useEffect } from 'react'
import Sidebar from 'components/Sidebar'
import Routes from './Routes'
import logo from 'assets/images/logo/Logo.svg'
import logoText from 'assets/images/logo/LogoText.svg'
import pages from 'constants/dashboardPages'
import Request from 'services/Request'


function Page() {
    const sidebarProps = { pages, logos: {logo, logoText} }
    
    useEffect(() => Request("/verify-token"), [])
    
    return (
        <div className="App d-flex">
            <Sidebar { ...sidebarProps }/>
            <div className="flex-grow-1">
                <Routes pages={ pages }/>
            </div>
		</div>
    )
}

export default Page

const languageMappings = {
    "English": "en",
    "Spanish": "es",
    "Finnish": "fi", 
    "Danish": "da", 
    "Norwegian": "no", 
    "Swedish": "sv"
}

const intentMappings = {
    "Informational": "informational",
    "Transactional": "transactional",
    "Navigational": "navigational",
    "Navigational informational": "navigational_informational",
    "Navigational transactional": "navigational_transactional",
    "Transactional download": "transactional_download"
}

const emotionMappings = {
    "Positive": true,
    "Negative": false
}

export { languageMappings, intentMappings, emotionMappings }
import React from 'react'
import Helper from './Helper'


function TextArea(props) {
    const { pageKey, inputValue, setInputValue, className } = props

    return (
        <div className={ `${className} col` }>
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <label htmlFor={ `${pageKey}-query` } className="label-text">
                        { pageKey }
                    </label>
                    <Helper itemKey={ pageKey } variant="gray" />
                </div>
            </div>
            <div>
                <textarea 
                    rows="5" 
                    className="form-control" 
                    id={ `${pageKey}-query` } 
                    placeholder={ `Paste/type your ${pageKey.toLowerCase()} here.` }
                    onChange={e => setInputValue(e.target.value)}
                    value={ inputValue }
                />
            </div>
            { props.children }
        </div>
    )
}

export default TextArea
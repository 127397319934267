import { errorMessages, constraints } from 'constants/messages'


const validateEmail = email => {

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const validateInput = (val, payloadKey) => {
    let regex;
    const value = val.trim()

    if (payloadKey === "URL") {
        regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
        return regex.test(value) ? null : errorMessages({invalid: "URL"})
    } else if (payloadKey === "InAppUrl") {
        const regexes = [/^https:\/\/apps.apple.com\/\S+\/app\/[a-z0-9-]+\/id\S+/g, /^https:\/\/play.google.com\/store\/apps\/details\S+/]
        return regexes.some(item => item.test(value)) ? null : errorMessages({invalid: "URL"}) + `${value.startsWith("http") ? ". Only Appstore and Playstore URLs allowed." : ""}`
    } else {
        const constraint = constraints[payloadKey]
        const values = payloadKey === "Keywords" ? value.split(",").filter(v => v.trim().length) : value
        const cues = {}

        if (values.length < constraint.min) cues["min"] = constraint.min
        if (values.length > constraint.max) cues["max"] = constraint.max

        if (Object.entries(cues).length) {
            if (payloadKey === "Keywords") cues["unit"] = payloadKey
            return errorMessages(cues)
        }

        if (payloadKey === "datasetName") {
            regex = /^[a-zA-Z0-9 _-]+$/g
            return regex.test(value) ? null : errorMessages({special: "dataset name"})
        }

        if (payloadKey === "Keywords") {
            return values.every(item => item.trim().length >= 2) ? null : errorMessages({invalid: "keywords length. Each keyword must be of at least 2 characters."})
        }
    }
}


export { validateInput, validateEmail }
import React, { useState, useEffect } from 'react';
import useWindowDimensions from 'utils/windowDimensions';
import SidebarItem from './SidebarItem';
import LogoSection from './LogoSection';
import LogoutSection from './LogoutSection';
import CollapseButton from './CollapseButton';

function Sidebar(props) {
    const { pages, logos } = props
    const [ collapseButtonVisibility, setCollapseButtonVisibility ] = useState(false);
    const [ collapse, setCollapse ] = useState(!!sessionStorage.getItem("collapsed") || false);
    const { width } = useWindowDimensions();
    collapse ? sessionStorage.setItem("collapsed", "collapsed") : sessionStorage.removeItem("collapsed") 

    useEffect(() => setCollapse(width < 1024), [ width ])


    return (
        <div className={ `bg-white sidebar d-flex flex-column justify-content-between ${ collapse && 'collapsed'}` }
            onMouseEnter={() => setCollapseButtonVisibility(true)} 
            onMouseLeave={() => setCollapseButtonVisibility(false)}
        >
            <div>
                <LogoSection logos={ logos } collapse={ collapse } />
                <div className={ `${ collapse || "menu" } my-4` } >
                    {
                        pages.map((item, idx) => (
                                <div key={ idx }>
                                    <SidebarItem item={ item } collapse={ collapse } />
                                </div>
                            )
                        )
                    }
                </div>
            </div>
            <LogoutSection collapse={ collapse } />
            <CollapseButton collapseButtonVisibility={ collapseButtonVisibility } collapse={ collapse } setCollapse={ setCollapse } />
        </div>
    )
}

export default Sidebar

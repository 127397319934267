import InAppUrlQuery from "app/Dashboard/CreateDataset/InApp/UrlQuery"
import { TextArea, InputBox } from "components";

const webTabs = {
    Keywords: TextArea,
    URL: InputBox,
    Description: TextArea
}

const inAppTabs = {
    InAppUrl: InAppUrlQuery
}

export { inAppTabs, webTabs }
import React, { useEffect } from 'react'
import InApp from './InApp'
import Web from './Web'

function TargetingDetails(props) {
    const { medium, setTitle, setMilestone } = props

    useEffect(() => setTitle("Targeting details"), [])

    return (
        <div>
            <button className="back-button btn btn-primary px-4" onClick={() => setMilestone(0)}>Back</button>
            {
                medium === "In-app" ? (
                    <InApp { ...props } />
                ) : (
                    <Web { ...props } />
                )
            }            
        </div>
    )
}

export default TargetingDetails

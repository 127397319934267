import jwtDecode from "jwt-decode";

const portal = "advertiser"
const tokenName = "ninaData"
const urlOf = path => process.env.REACT_APP_BACKEND_API_HOST + path  

const buildRequest = (path, payload, headers, method) => {
	let options;
	const token = localStorage.getItem(tokenName)
	const url = urlOf(path)

	headers['Content-Type'] = "application/json"
	if (!("Authorization" in headers) && token) headers['Authorization'] = `Bearer ${token}`

	if (method === "POST") {
		options = {
			method,
			body: JSON.stringify(payload),
			headers: headers
		};
	}

	return { url, options }
}


const fetchResponse = async (path, payload, headers, method) => {
	const { url, options } = buildRequest(path, payload, headers, method)
	const response = await fetch(url, options)
	const statusCode = response.status
	const jsonResponse = statusCode === 204 ? {} : await response.json()
	
	return { statusCode, jsonResponse }
}


const Request = async (path, payload = {}, headers = {}, method = "POST") => {
	let response = await fetchResponse(path, payload, headers, method)
	const { statusCode, jsonResponse } = response

	if (statusCode === 401 && "msg" in jsonResponse) window.location.replace("/accounts/login")
	if ("access_token" in jsonResponse) {
		const access_token = jsonResponse.access_token
		const decoded_token = jwtDecode(access_token)

		if (decoded_token.portal !== portal) {
			return { statusCode: 403, jsonResponse: {"error": "Access denied."} }
		}

		localStorage.setItem(tokenName, access_token)
		delete jsonResponse.access_token

		if (statusCode === 202) {
			response = await fetchResponse(path, payload, {"Authorization": `Bearer ${access_token}`}, method)
		}
	}

	if ("info" in jsonResponse) return { statusCode: 202, jsonResponse: {"error": jsonResponse.info}}

	return response
}


export default Request;

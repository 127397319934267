import CreateDatasetIcon from 'assets/icons/sidebar/CreateDataset.svg';
import CreateDatasetActive from 'assets/icons/sidebar/CreateDataset-active.svg';
import AccountDetailsIcon from 'assets/icons/sidebar/AccountDetails.svg';
import AccountDetailsIconActive from 'assets/icons/sidebar/AccountDetails-active.svg'
import AccountDetails from 'app/Dashboard/AccountDetails';
import CreateDataset from 'app/Dashboard/CreateDataset';


const dashboardPages = [
    {
        icon: CreateDatasetIcon,
        selected: CreateDatasetActive,
        text: "Create dataset",
        path: "/create-dataset",
        key: "createDataset",
        component: CreateDataset
    }, 
    {
        icon: AccountDetailsIcon,
        selected: AccountDetailsIconActive,
        text: "Account details",
        path: "/account-details",
        key: "accountDetails",
        component: AccountDetails
    }
]

export default dashboardPages

import Request from "./Request"


const analyze = async (activeTab, inputValue) => {
    const pageKey = activeTab.toLowerCase()
    const payload = {};
    payload[pageKey] = pageKey === "keywords" ? inputValue.replace(/^,|,$/g, "").split(",").map(i => i.trim()) : inputValue

    try {

        const { statusCode, jsonResponse } = await Request(`/v2/analyze/${pageKey}`, payload)

        if (statusCode === 400) return {error: `Failed to analyze ${activeTab}. Please try again.`}
        if ("info" in jsonResponse) return {error: jsonResponse.info}

        if ("taxonomies" in jsonResponse) {
            jsonResponse.taxonomies = Object.entries(jsonResponse.taxonomies).sort(([,x], [,y]) => y - x).map(item => item[0])
        }

        return jsonResponse

    } catch (error) {
        return {error: "Server error. Try again after sometime."}
    }
}

export { analyze }
const pathFor = {
    InAppUrl: "/recommendation-engine/in-app",
    RecommendationEngine: "/recommendation-engine/web",
    URL: "/keywords-generator/url",
    Description: "/keywords-generator/description",
    AccountDetails: "/account-details",
    LinkDV360: "/link-dv360",
    DeLinkDV360: "/delink-dv360",
    FetchDV360s: "/fetch-dv360-accounts",
    Publish: "/publish-to-dv360"
}

export { pathFor }

import CreateDataset from 'app/Dashboard/CreateDataset/CreateDataset'
import TargetingDetails from 'app/Dashboard/CreateDataset/TargetingDetails'
import PreviewDataset from 'app/Dashboard/CreateDataset/PreviewDataset'
import Publish from 'app/Dashboard/CreateDataset/Publish'

const pageConstants = [
    {
        key: "createDataset",
        title: "Create dataset",
        component: CreateDataset
    }, {
        key: "targetingDetails",
        title: "Targeting details",
        component: TargetingDetails
    }, {
        key: "previewDataset",
        title: "Preview dataset",
        component: PreviewDataset
    }, {
        key: "publish",
        title: "Publish",
        component: Publish
    }
]

export default pageConstants

const constraints = {
    datasetName: { min: 8, max: 20 },
    Keywords: { min: 8, max: 100 },
    Description: { min: 500, max: 2000 },
}


const errorMessages = cues => {
    const { min, max, invalid, unit } = cues

    const messages = {
        min: `Minimum ${min} ${unit || "characters"} required`,
        max: `Maximum ${max} ${unit || "characters"} allowed`,
        special: "Special characters not allowed",
        invalid: `Invalid ${invalid}`
    }

    return Object.entries(messages).filter(([k, v]) => k in cues)[0][1]
}

const helperTexts = Object.fromEntries(Object.entries(constraints).map(([k, v]) => [
    k, 
    `Minimum ${v.min} and maximum ${v.max} ${k === "Keywords" ? "keywords" : "characters"} allowed.`
]))

helperTexts["InAppUrl"] = "Only Android PlayStore and IOS AppStore app URLs allowed."
helperTexts["avgScore"] = input => `Average relevancy score of the keywords extracted from the ${input} provided.`
helperTexts["rank"] = input => `Top two taxonomies the ${ input } ${ input.endsWith("s") ? "are" : "is" } related to.`

export { errorMessages, constraints, helperTexts }
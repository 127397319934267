import React, { useState } from 'react'
import { CSVLink } from "react-csv";
import CaretDown from './assets/caretDown.png'
import Download from './assets/download.png'


function ExportButton(props) {
    const { exportData, variant, exportFileName, extraButton } = props
    const [ showMenu, setShowMenu ] = useState(false)
    const filename = exportFileName || (new Date()).toISOString()

    return (
        <>
            {
                variant === "dropdown" ? (
                    <div className="d-flex flex-column align-items-end" onClick={() => setShowMenu(!showMenu)}>
                        <button data-testid="export-dropdown" className="btn btn-white p-2 drop-down d-flex justify-content-between">
                            Export as
                            <img src={ CaretDown } alt="caret-down" width="20" height="20"/>
                        </button>
                        <div className={ `d-${ showMenu ? "flex" : "none"} drop-down-menu py-1` }>
                            <CSVLink
                                data={ exportData }
                                filename={ `${filename}.csv` }
                                className="drop-down-item"
                                target="_blank"
                            >
                                CSV
                            </CSVLink>
                        </div>
                    </div>
                ) : (
                    <>
                        <CSVLink
                            data={ exportData }
                            data-testid="export-dropdown"
                            filename={ `${filename}.csv` }
                            className="px-3 py-2 rounded border--light"
                            id="exportButton"
                            target="_blank"
                        >
                            Download 
                            <img src={ Download } alt="download" height="14" className=" pl-4" />
                        </CSVLink>
                    </>
                )
            }
            {
                extraButton && (
                    <button className='btn btn-primary px-4 ml-3 py-2' onClick={ extraButton.onClick }>{ extraButton.text }</button>
                )
            }
        </>
    )
}

export default ExportButton

import React, { useEffect, useState } from 'react'
import Stepper from './Stepper'
import pageConstants from 'constants/pageConstants'
import NinaDataLoader from 'components/NinaDataLoader'

function Page(props) {
    const [ datasetName, setDatasetName ] = useState("")
    const [ medium, setMedium ] = useState("Web")
    const [ milestone, setMilestone ] = useState(0)
    const [ results, setResults ] = useState([])
    const [ pageStates, setPageStates ] = useState({})
    const [ ninaLoader, setNinaLoader ] = useState(false)

    const Component = pageConstants[milestone].component
    const componentProps = { datasetName, setDatasetName, medium, setMedium, setMilestone, results, setResults, pageStates, setPageStates, ninaLoader, setNinaLoader }

    useEffect(() => setPageStates({}), [medium])

    return (
        <div className="my-4 mx-4">
            <Stepper milestone={ milestone } setMilestone={ setMilestone } />
            <Component { ...componentProps } { ...props } />
            { ninaLoader && <NinaDataLoader /> }
        </div>
    )
}

export default Page

import React from 'react'
import pageConstants from 'constants/pageConstants'

function Stepper(props) {
    const { milestone, setMilestone } = props
    
    return (
        <div className="d-flex justify-content-center">
            <div className="bg--light d-flex rounded justify-content-between px-5 py-3 stepper">
                {
                    pageConstants.map((item, idx) => (
                        <div className="d-flex flex-column">
                            <span className={ `${idx <= milestone && "text-active"} pb-2` }>{ item.title }</span>
                            <div className={ `circle ${idx <= milestone && "active pointer"} align-self-center` }
                                onClick={() => idx <= milestone && setMilestone(idx)}
                            ></div>
                        </div>
                    ))
                }
            </div>
            <hr className="stepper-line" />
        </div>
    )
}

export default Stepper

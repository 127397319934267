import React, { useState } from 'react'
import { inAppTabs } from 'constants/tabConstants'

function QueryPages(props) {
    const [ activeTab, setActiveTab ] = useState(Object.keys(inAppTabs)[0])
    const Component = inAppTabs[activeTab]


    return (
        <div>
            <div className="d-flex justify-content-start tab-section-border mt-5">
            {
                Object.keys(inAppTabs).map(key => (
                    <div className="d-flex flex-column justify-content-start tabs" key={ key }>
                        <button
                            type="button"
                            className={ `bg-white pb-3 px-4 border-0 tab-${ key === activeTab && "active"}` }
                            onClick={() => setActiveTab(key)}
                        >
                            <span className="px-1">URL</span>
                        </button>
                        {
                            key === activeTab && ( 
                                <div className="bg-white p-0 outline-active"></div>
                            )
                        }
                    </div>
                ))
            }
            </div>
            <Component { ...props } />
        </div>
    )
}

export default QueryPages

import React from 'react'


function Radios(props) {
    const { items, selected, setSelected, childClassName } = props

    return (
        <div className="row">
            {
                items.length ? items.map(item => (
                    <div data-testid={ `radio-${item}` } className={ childClassName + " d-flex align-items-center pointer" } onClick={() => setSelected(item)} >
                        <span className={ `radio-button ${ selected === item ? "filled" : "hollow"} mr-2 d-flex align-items-center justify-content-center` }>
                            <span className={ `${ selected === item && "radio-fill"}` }></span>
                        </span>
                        <span>{ item }</span>
                    </div>
                )) : (
                    <span>No items available.</span>
                )
            }            
        </div>
    )
}

export default Radios

import { languageMappings } from "./mappings"

const filterOptions = {
    "Web": [
        {
            key: "lang",
            defaultValue: "language",
            options: Object.entries(languageMappings).map(([k, v]) => {
                const tempObj = {}
                tempObj[`${v.toUpperCase()}`] = k
                return tempObj
            })
        },
        {
            key: "is_positive_emotion",
            defaultValue: "emotion",
            options: ["Positive", "Negative"]
        }
    ],
    "In-app": [
        {
            key: "store_name",
            defaultValue: "stores",
            options: ["Android PlayStore", "IOS AppStore"]
        }
    ]
}


export { filterOptions }
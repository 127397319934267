import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Auth from './Auth'
import Dashboard from './Dashboard'
import { PrivateRoute } from 'components'

function Routes() {
    
    return (
        <Router>
            <Switch>
                <Route path="/accounts">
                    <Auth />
                </Route>
                <PrivateRoute path="/dashboard">
                    <Dashboard />
                </PrivateRoute>
                <Redirect to="/dashboard"/>
            </Switch>
        </Router>
    )
}

export default Routes
import React, { useEffect, useState } from 'react';
import Request from 'services/Request';
import { pathFor } from 'constants/apiPaths';
import { accountDetailsTable } from 'constants/tableConstants'
import { Loading, Table } from 'components';
import DV360Accounts from './CreateDataset/DV360Accounts';


function AccountDetails(props) {
    const { setMessageQueue, setTitle } = props
    const [ details, setDetails ] = useState(null)
    const [ hasLoaded, setHasLoaded ] = useState(false)
    const [ existingDV360s, setExistingDV360s ] = useState([])
    
    const fetchDV360s = async () => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.FetchDV360s)
            if (statusCode === 200) setExistingDV360s(jsonResponse.data.map(item => item.label))
        } catch (err) {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }

    useEffect(async () => {
        setTitle("Account details")
        setMessageQueue([])
        setHasLoaded(true)
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.AccountDetails)
            if(statusCode === 200) setDetails(jsonResponse.data)
        } catch (error) {
            setHasLoaded(false)
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
        fetchDV360s()
    }, [])


    return (
        <div className="m-4">
            <p className="label-text">USER DETAILS</p>
            {
                details ? (
                    <div>
                        <div className="widgets px-4 py-2">
                            <Table tableData={ {tableHead: accountDetailsTable, tableBody: details} } className="table table-borderless table-striped"/>
                        </div>
                        <span className="d-block pt-3">Note: Contact your Account Manager for any changes.</span>
                    </div>
                ) : hasLoaded ? (
                    <Loading />
                ) : <></>
            }
            <p className="label-text mt-5">DV360 ACCOUNTS</p>
            <div className='col-5 p-0'>
                 <DV360Accounts { ...{ existingDV360s, fetchDV360s, setMessageQueue } } />
            </div>
        </div>
    )
}

export default AccountDetails

const previewWebDataset = [
    {
        key: "url",
        title: "URL",
    },
	{
		key: "lang",
		title: "Language"
	},
    {
        key: "is_positive_emotion",
        title: "Emotion",
    },
	{
		key: "intent",
		title: "Intent"
	},
	{
		key: "taxonomy",
		title: "Taxonomy"
	},
	{
		key: "score",
		title: "Taxonomy score"
	}
];

const previewInAppDataset = [
	{
		key: "url",
		title: "URL",
	},
	{
		key: "store_name",
		title: "Store",
	},
]

const accountDetailsTable = [
	{
		key: "name",
		title: "Name",
	},
	{
		key: "email",
		title: "Email",
	},
	{
		key: "company_name",
		title: "Company",
	},
];

const taxonomiesTable = [
	{
		key: "taxonomy",
		title: "Taxonomy category"
	}, 
	{
		key: "rank",
		title: "Rank"
	}
]

export { previewWebDataset, previewInAppDataset, accountDetailsTable, taxonomiesTable };

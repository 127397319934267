import React, { useState } from 'react'
import PageLength from './PageLength'
import Pagination from './Pagination'
import Table from './Table'
import InfoDisplay from './InfoDisplay'
import ExportButton from './ExportButton'
import sortData from './utils/sortData'
import generateExportData from './utils/generateExportData'
import filterData from './utils/filterData'


function DataTable(props) {
    const { tableData, setMessageQueue, noExport, exportBtnType, exportFileName, filterBy, extraButton } = props
    const [ sortProps, setSortProps ] = useState(null)
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ pageLength, setPageLength ] = useState(10)

    const tableHead = tableData.tableHead
    const tempBody =  filterBy ? filterData(tableData.tableBody, filterBy) : tableData.tableBody
    const tableRows = tempBody ? sortData(tempBody, sortProps) : []

    const total = tableRows.length
    const start = total ? pageLength * (currentPage - 1) : 0
    const count = pageLength * currentPage
    const end = !total ? 0 : total < count ? total : count  
    const tableBody = tableRows.slice(start, end)

    const exportData = generateExportData(tableHead, tableBody)
    const paginationProps = { total, pageLength, currentPage, setCurrentPage }
    
    return (
        <div>
            {
                noExport ? <></> : (
                    <div className={ `d-${ noExport ? "none" : "flex" } justify-content-between align-items-baseline mb-2` } >
                        <p>RESULTS</p>
                        <div className="d-flex justify-content-end align-items-center">
                            { props.children }
                            <PageLength pageLength={ pageLength } setPageLength={ setPageLength }/>      
                            <InfoDisplay info={ { start, end, total } } />      
                            <ExportButton exportData={ exportData } variant={ exportBtnType } exportFileName={ exportFileName } extraButton={ extraButton }/>
                        </div>
                    </div>
                )
            }
            <div className="card w-100">
                <Table 
                    className="table table-borderless table-striped"
                    tableData={ { tableHead, tableBody } } 
                    setSortProps={ setSortProps }
                    setMessageQueue={ setMessageQueue }
                />
            </div>


            <div className="text-right mt-4">
                <Pagination { ...paginationProps } />
            </div>
            
        </div>
    )
}

export default DataTable

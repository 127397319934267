import React, { useState } from 'react'
import CaretDown from 'assets/icons/misc/caretDown.png'


function DropDown(props) {
    const { options, setSelected, defaultValue, className, childClassName } = props
    const [ showMenu, setShowMenu ] = useState(false)
    const [ displayText, setDisplayText ] = useState("")


    return (
        <div className={ className } onClick={() => setShowMenu(!showMenu)}>
            <button className={ `btn btn-white drop-down d-flex justify-content-between mt-1 pt-2 ${ childClassName }` }>
                <span className="text-truncate">{ displayText.trim().replace("-", "") || defaultValue }</span>
                <img src={ CaretDown } alt="caret-down" height="23"/>
            </button>
            <div className={ `d-${ showMenu ? "block" : "none"} drop-down-menu py-1 ${ childClassName }` }>
                {
                    options.map(item => {
                        let displayValue, value;
                        displayValue = value = item;

                        if (item instanceof Object) {
                            value = Object.keys(item)[0]
                            displayValue = Object.values(item)[0]
                        } 

                        if (displayText === displayValue) {
                            displayValue = "-"
                            value = ""
                        }
                        
                        return (
                            <div className="drop-down-item" data-testid={ `drop-down-item-${displayValue} ` } onClick={() => {
                                setDisplayText(displayValue);
                                setSelected(value); 
                            }}>{ displayValue }</div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DropDown

import React, { useEffect, useState } from "react";
import DV360Targets from './DV360Targets'
import Request from 'services/Request'
import { pathFor } from 'constants/apiPaths'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare, faTrashCan }  from '@fortawesome/free-regular-svg-icons'
import { useGoogle } from "services/useGoogle";
import { emotionMappings, intentMappings, languageMappings } from "constants/mappings";
import DV360Accounts from "./DV360Accounts";


function Publish(props) {
    const { setMilestone, setTitle, setMessageQueue, pageStates } = props
    const [ existingDV360s, setExistingDV360s ] = useState([])
    const [ targets, setTargets ] = useState([])
    const [ addDV360, setAddDV360 ] = useState(false)
    const [ newDV360, setNewDV360 ] = useState("")
    const [ error, setError ] = useState(null)
    const [ addTarget, setAddTarget ] = useState(false)
    const [ editTargetKey, setEditTargetKey ] = useState(null)
    const [ authCredentials, setAuthCredentials ] = useState(null)
    const [ published, setPublished ] = useState(false)
    const [ fetchedAccounts, setFetchedAccounts ] = useState(null)
    const googleClient = useGoogle(setAuthCredentials)
    const DV360TargetsProps = { existingDV360s, targets,  editTargetKey, published, setTargets, setAddTarget, setEditTargetKey }

    const fetchDV360s = async () => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.FetchDV360s)
            if (statusCode === 200) {
                const { data } = jsonResponse
                setFetchedAccounts(data)
                setExistingDV360s(data.map(item => item.label))
            }
        } catch (err) {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }

    useEffect(async () => {
        setMessageQueue([])
        setTitle("Publish")
        if (authCredentials) {
            if ("error" in authCredentials) return setMessageQueue([{error: "Authentication incomplete."}])
            try {
                const { statusCode, jsonResponse } = await Request(pathFor.LinkDV360, { ...authCredentials, label: newDV360})
                if (statusCode === 200) {
                    setAddDV360(false)
                }
                setMessageQueue([jsonResponse])
            } catch (err) {
                setMessageQueue([{error: "Server error. Try again after sometime."}])
            }
        }
        fetchDV360s()
    }, [authCredentials])

    const handleLink = async () => {
        setError(null)
        if (existingDV360s.includes(newDV360)) return setError("Label name already exists.")
        googleClient.requestCode()
    }

    const constructPublishPayload = () => {
        const tempTargets = []
        targets.forEach(target => {
            const { accountId, userType, userId, channelId, channelName } = target
            const tempTarget = { "account_id": fetchedAccounts.filter(item => item["label"] === accountId)[0]["_id"] }
            channelId ? tempTarget["channel_id"] = channelId : tempTarget["channel_name"] = channelName
            tempTarget[`${userType.toLowerCase()}_id`] = userId
            tempTargets.push(tempTarget)
        })

        const queryType = pageStates.activeTab
        const queried = pageStates[queryType]
        const queryInput = queried.inputValue
        const emotion = queried.emotionCheckboxes

        const query = {
            taxonomy: queried.queryResults.taxonomies,
            intent: queried.intentCheckboxes.map(intent => intentMappings[intent]),
            lang: queried.languageCheckboxes.map(language => languageMappings[language])
        }

        if (emotion.length === 1) query["is_positive_emotion"] = emotionMappings[emotion[0]]

        const payload = { targets: tempTargets, query }
        payload[queryType.toLowerCase()] = queryInput

        return payload
    }

    const publish = async () => {
        setMessageQueue([])
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.Publish, constructPublishPayload())
            if (statusCode === 200) setPublished(true)
            setMessageQueue([jsonResponse])
        } catch (err) {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }

    const clear = () => {
        setTargets([])
        setPublished(false)
        setMessageQueue([])
    }

    useEffect(async () => {
        setError(null)
        setMessageQueue([])
    }, [newDV360, addDV360, addTarget])


    useEffect(() => {
        if (!existingDV360s.length) setTargets([])
    }, [existingDV360s])

    return (
        <div className="mt-5">
            <button className="back-button btn btn-primary px-4" onClick={() => setMilestone(2)}>Back</button>
            <div className='row'>
                <div className='col-6 pr-5'>
                    <DV360Accounts { ...{ existingDV360s, fetchDV360s, setMessageQueue } } />
                    {
                        addDV360 && (
                            <div className='w-100 px-4 py-3 border rounded mb-3 d-flex align-items-center'>
                                <span className='label-text'>Name</span>
                                <div className='flex-grow-1 my-1 pr-4'>
                                    <input className='border--light rounded px-3 py-2 ml-4 w-100' onChange={e => setNewDV360(e.target.value)}/>
                                    {
                                        error && (
                                            <span className='text-danger position-absolute ml-4'>{error}</span>
                                        )
                                    }
                                </div>
                                <button className='btn btn-primary px-3 ml-3 py-2' onClick={ handleLink } disabled={ !newDV360.length }>&nbsp; Link&nbsp;&nbsp;</button>
                            </div>
                        )
                    }
                    <button className='btn btn-primary px-3 py-2' onClick={() => setAddDV360(!addDV360)}>
                        { addDV360 ? "Cancel" : (
                            <>
                                <FontAwesomeIcon icon={ faPlus } size="1x" className='mr-2'/>
                                Add DV360
                            </>
                        ) }
                    </button>
                </div>
                <div className='col-6 border-left pl-5'>
                    {
                        targets && targets.map((item, idx) => (
                            <>{
                                editTargetKey && parseInt(editTargetKey) === idx ? (
                                    <DV360Targets { ...DV360TargetsProps } />
                                ) : (
                                    <div className={ `row border rounded p-3 mx-auto mb-3 ${ published && "bg--secondary"}` } data-testid={ `target-${idx}` } >
                                        <div className='col-3 text-center py-1'>
                                            <span className='label-text d-block'>Account ID</span>
                                            <span>{ item.accountId }</span>
                                        </div>
                                        <div className='col-3 border-left text-center py-1'>
                                            <span className='label-text d-block'>{ item.userType } ID</span>
                                            <span>{ item.userId }</span>
                                        </div>
                                        <div className='col-3 border-left text-center py-1'>
                                            <span className='label-text d-block'>Channel</span>
                                            <span>{ item.channelId || item.channelName }</span>
                                        </div>
                                        <div className='col-3 d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={ faPenToSquare } size="lg" className={ `text-${ published ? "-body" : "secondary pointer"}` } onClick={() => !published && setEditTargetKey(`${idx}`)}/>
                                            <FontAwesomeIcon icon={ faTrashCan } size="lg" className={ `text--${ published ? "body" : "red pointer"} ml-4` } onClick={() => !published && setTargets(targets.filter((_, idy) => idy != idx)) }/>
                                        </div>
                                    </div>
                                )
                            }</>
                        ))
                    }
                    {
                        addTarget && !editTargetKey && (<DV360Targets { ...DV360TargetsProps } />)
                    }
                    <div className='d-flex justify-content-between'>
                        <button className='btn btn-primary px-3 py-2' onClick={() => setAddTarget(!addTarget)} disabled={ !existingDV360s.length || editTargetKey || published }>
                            { addTarget ? "Cancel" : (
                                <>
                                    <FontAwesomeIcon icon={ faPlus } size="1x" className='mr-2'/>
                                    Add target
                                </>
                            ) }
                        </button>
                        {
                            targets && targets.length ? (
                                <button className='btn btn-primary px-3 py-2' onClick={ published ? clear : publish } disabled={ editTargetKey || addTarget }>
                                   &nbsp;{ published ? "Clear" : "Publish" }
                                </button>
                            ) : (<></>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Publish


import React from 'react'
import MoreActions from './MoreActions'
import Anchor from './Anchor'
import { calculateBytes } from './utils/bytesCalculator'
import TruncatedText from './TruncatedText'

function TableBody(props) {
    const { tableBody, tableHead, setMessageQueue } = props

    const displayText = (tr, th) => {
        let text = ""
        let val = tr[th.key]
        if(th.prefix && val >= 0) text += th.prefix
        if(th.isBoolean) {
            val = val ? "Yes" : th.default || "No"
        } if(th.isStatus) {
            val = val ? "Active" : "-"
        } else if(th.isBytes) {
            val = calculateBytes(val)
        } else if(th.key === "url") {
            return <Anchor url={ val } />
        } else if(typeof val === "string" && val.length > 100) {
            return <TruncatedText value={ val } length={ 100 } />
        } else if(typeof val === "number") {
            val = val ? val.toFixed(2) : "0"
        } else {
            val = !th.actions ? val || th.default : null
        }
        text += val
        if (th.suffix) text += ` ${th.suffix}`

        return text
    }

    return (
        <>
           {
                tableBody && tableBody.length ? (
                    tableBody.map((tRow, idx) => (
                        <tr key={ idx }>
                            {
                                tableHead.map((tHead, idy) => (
                                    <td key={ idy } 
                                        className={ tHead.colored && `text--${tRow.color}` }
                                    >
                                        <span>
                                            { 
                                                tHead.actions ? (
                                                    <MoreActions actions={ tHead.actions } row={ tRow } setMessageQueue={ setMessageQueue } />
                                                ) : (
                                                    displayText(tRow, tHead)
                                                )
                                            }
                                        </span> 
                                    </td>
                                ))
                            }

                        </tr>
                    ))
                ) : (
                    <tr className="bg--secondary text-center">
                        <td colspan={ tableHead.length + 1 } className="py-3 rounded">
                            No data to display
                        </td>
                    </tr>
                )
                
            } 
        </>
    )
}

export default TableBody

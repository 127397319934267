import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';

function Pagination(props) {
    const { total, pageLength, currentPage, setCurrentPage } = props
    const totalPages = Math.ceil(total / pageLength) || 1
    const active = currentPage > totalPages ? setCurrentPage(totalPages) : currentPage
    let maxPage, minPage, ellipses;
    let pageRange = [];
    if(active <= 3) {
        maxPage = totalPages <= 5 ? totalPages : 5
        minPage = 1
        ellipses = totalPages > 5 ? { after: "......" } : {}
    } else if(active >= totalPages - 2) {
        maxPage = totalPages
        minPage = totalPages - 4
        ellipses = { before: "......" }
    } else {
        maxPage = active + 2
        minPage = active - 2
        ellipses = { before: "...", after: "..." }
    }
    for(let i = minPage; i <= maxPage; i++) pageRange.push(i)

    return (  
        <div>
            <button className="paginate-button" data-testid="paginate-left" onClick={() => setCurrentPage(active - 1) } disabled={ active === 1 }>
                <FontAwesomeIcon icon={ faCaretLeft } />
            </button>
            {
                "before" in ellipses && (
                    <span>{ ellipses.before }</span>
                )
            }
            {
                pageRange.map((page, idx) => (
                    <span 
                        data-testid={ `pagination-button-${idx}` }
                        key={ idx }
                        onClick={() => setCurrentPage(page)}
                        className={ `page-no ${ page === active && "active" }` }
                    >
                        { page }
                    </span>
                ))
            }
            {
                "after" in ellipses && (
                    <span>{ ellipses.after }</span>
                )
            }
            <button className="paginate-button" data-testid="paginate-right" onClick={() => setCurrentPage(active + 1)} disabled={ active === totalPages }>
                <FontAwesomeIcon icon={ faCaretRight } />
            </button>
        </div>
    )
}

export default Pagination

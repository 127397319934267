import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'


function PrivateRoute(props) {
    const { ...rest } = props
    const [ ninaToken, setNinaToken ] = useState(localStorage.getItem("ninaData"))

    useEffect(() => setNinaToken(localStorage.getItem("ninaData")), [])

    return (
        <div>
            {
                ninaToken ? (
                    <Route { ...rest } >
                        {props.children}
                    </Route>
                ) : (
                    <Redirect to="/accounts/login" />
                )
            }
        </div>
    )
}

export default PrivateRoute

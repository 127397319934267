import React, { useEffect, useState } from 'react'
import Request from 'services/Request'
import { pathFor } from 'constants/apiPaths'
import { validateInput } from 'utils/validations'
import { previewInAppDataset } from 'constants/tableConstants'
import { CheckBoxes, Helper } from 'components'


function UrlQuery(props) {
    const pageKey = "in-app-url-query"
    const { setMessageQueue, setNinaLoader, setMilestone, setResults, pageStates, setPageStates } = props
    const pageState = pageKey in pageStates ? pageStates[pageKey] : null
    const [ inputValue, setInputValue ] = useState(pageState ? pageState.inputValue || "" : "")
    const [ inputCheckbox, setInputCheckbox ] = useState(pageState ? pageState.inputCheckbox || [] : [])  
    const [ errorMessage, setErrorMessage ] = useState(null)
    const disabled = !inputValue.trim() ||  !inputCheckbox.length

    const handleChange = event => {
        setErrorMessage(null)
        setInputValue(event.target.value)
    }

    useEffect(() => setErrorMessage(null), [inputCheckbox])

    const handleClick = async (event) => {
        event.preventDefault()
        setMessageQueue([])
        setResults(null)
        setNinaLoader(true)

        const tempState = { ...pageStates }
        tempState.activeTab = pageKey
        tempState[pageKey] = { inputValue, inputCheckbox }
        setPageStates(tempState)

        const error = validateInput(inputValue, "InAppUrl")

        if (!error) { 
            try {
                
                const { statusCode, jsonResponse } = await Request(pathFor.InAppUrl, { url: inputValue.trim(), stores: inputCheckbox })
                if (statusCode === 200) {

                    setResults({ 
                        tableData: { 
                            tableHead: previewInAppDataset, 
                            tableBody: jsonResponse.urls.map(item => {
                                let data = { ...item }
                                data.store_name =  ["IOS AppStore", "Android PlayStore"].filter(item => item.split(" ")[1].toLowerCase() === data.store_name)[0]
                                return data
                            }) 
                        } 
                    })
                    setMilestone(2)
                } else if (statusCode === 400) {
                    setMessageQueue([{error: "Failed to suggest URLs. Please try again."}])
                } else {
                    setMessageQueue([jsonResponse])
                }
            } catch (error) {
                setMessageQueue([{error: "Server error. Try again after sometime."}])
            }
        } else {
            setErrorMessage(error)
        }
        setTimeout(() => setNinaLoader(false), 300)
    }

    return (
        <div>
            <div className="d-flex mt-4 flex-column">
                <div className="form-group form-group-custom  col-6 p-0">
                    <div className="d-flex">
                        <label htmlFor="url-search" className="label-text">
                            URL
                        </label>
                        <Helper itemKey="inAppUrl" variant="gray" />
                    </div>
                    
                    <input 
                        type="text" 
                        className="form-control" 
                        id="url-search"
                        placeholder="https://www.google.com/" 
                        onChange={ handleChange } 
                        value={ inputValue }       
                    />
                    {
                        errorMessage && (
                            <span className="text-danger error-text">{ errorMessage }</span>
                        )
                    }
                </div>
                <div className="mt-4 pt-2">
                    <label className="label-text">Stores</label>
                    <div className="ml-2">
                        <CheckBoxes items={ ["Android PlayStore", "IOS AppStore"] } selected={ inputCheckbox } setSelected={ setInputCheckbox } childClassName="col-4 my-2" />
                    </div>
                </div>
                <div className="text-right">
                    <button type="submit" className="btn btn-primary ml-3 mt-4 px-4" onClick={ handleClick } disabled={ disabled }>Suggest URLs</button>
                </div>
            </div>
        </div>
    )
}

export default UrlQuery

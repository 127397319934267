import React, { useEffect, useState } from 'react'
import { Radios, Helper } from 'components'
import { validateInput } from 'utils/validations'


function CreateDataset(props) {
    const { setMessageQueue, setTitle, setDatasetName, setMilestone, setMedium, medium, datasetName } = props
    const [ inputValue, setInputValue ] = useState(datasetName)
    const [ inputRadio, setInputRadio ] = useState(medium)
    const [ errorMessage, setErrorMessage ] = useState(null)
    const dataSetName = inputValue.trim()

    const handleChange = event => {
        setErrorMessage(null)
        setInputValue(event.target.value)
    }

    const handleClick = async (event) => {
        event.preventDefault()
        setMessageQueue([])

        const error = validateInput(dataSetName, "datasetName")

        if (!error) {
            setDatasetName(inputValue)
            setMedium(inputRadio)
            setMilestone(1)
        } else {
            setErrorMessage(error)
        }
    }

    useEffect(() => setTitle("Create dataset"), [])

    return (
        <div className="d-flex flex-column mt-5">
            <div className="form-group form-group-custom  col-5 p-0 mb-4">
                <div className="d-flex">
                    <label htmlFor="dataset-name" className="label-text d-flex justify-content-between">
                        Dataset Name
                    </label>
                    <Helper itemKey="datasetName" variant="gray" />
                </div>
                <input 
                    type="text" 
                    className="form-control" 
                    id="dataset-name" 
                    onChange={ handleChange }
                    value={ inputValue }
                />
                {
                    errorMessage && (
                        <span data-testid="dataset-name-error" className="text-danger error-text">{ errorMessage }</span>
                    )
                }
            </div>
            <span className="label-text mt-2 py-2">Medium</span>
            <div className="ml-2">
                <Radios items={ ["Web", "In-app"] } selected={ inputRadio } setSelected={ setInputRadio } childClassName="col-4" />
            </div>
            <button type="submit" className="btn btn-primary px-5 py-1 align-self-end" onClick={ handleClick } disabled={ !dataSetName || !inputRadio.trim() }>Next</button>
        </div>
    )
}

export default CreateDataset

import React, { useEffect, useState } from 'react'
import { DataTable, DropDown } from 'components'
import { filterOptions } from 'constants/filterOptions'

function PreviewDataset(props) {
    const { setTitle, datasetName, setMilestone, results, medium } = props
    const [ filterBy, setFilterBy ] = useState(null)
    
    useEffect(() => setTitle("Preview dataset"), [])

    const handleFilter = (k, v) => {
        const temp = { ...filterBy }

        !!v.length ? temp[k] = v : delete temp[k]
        setFilterBy(temp)
    }

    const onClick = () => {
        setMilestone(3)
    }
    
    return (
        <div className="py-4">
            <button className="back-button btn btn-primary px-4" onClick={() => setMilestone(1)}>Back</button>
            {
                results && (
                    <DataTable { ...results } exportFileName={ datasetName } filterBy={ filterBy } extraButton={{ onClick, text: "Publish" }}>
                        {
                            filterOptions[medium].map(item => (
                                <DropDown options={ item.options } setSelected={(v) => handleFilter(item.key, v)} defaultValue={ `Select ${item.defaultValue}` }  className="options-filter" /> 
                            ))
                        }
                    </DataTable>
                )
            }
        </div>
    )
}

export default PreviewDataset

import React, { useState } from 'react'
import { helperTexts } from 'constants/messages'

function Helper(props) {
    const { itemKey, variant, arg } = props
    const [ displayText, setDisplayText ] = useState(false)
    const text = helperTexts[itemKey] 

    return (
        <div onMouseEnter={() => setDisplayText(true)} onMouseLeave={() => setDisplayText(false)}>
            <div className={ `helper helper-${variant} mx-1` }>?</div>
            <div className={ `label-text helper-text shadow d-${displayText ? "flex" : "none"}` }>
                { 
                    text instanceof Array ? (
                        <ul className="m-0 p-0 ml-3">
                            {
                                text.map(item => (
                                    <li className="mt-1">{ item }</li>
                                ))
                            }
                        </ul>
                    ) : text instanceof Function ? (
                        <>{ text(arg) }</>
                    ) : (
                        <>{ text }</>
                    )
                }
            </div>
        </div>
    )
}

export default Helper
